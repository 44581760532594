import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
const { shape } = PropTypes;
import get from 'lodash.get';
import { Helmet } from 'react-helmet';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { LivePlayerProvider } from 'context/LivePlayerContext';
import LoggedInLiveVideo from 'routes/Home/components/LoggedInLiveVideo';
import livePlayerBlockShape from 'shapes/components/livePlayerBlockShape';
import LivePlayerCoverContent from './components/LivePlayerCoverContent';
import { usePictureInPictureContext } from 'context/PictureInPictureContext';
import { autoOptimizeImage } from 'utils/functions';
import { useAuth } from 'context/AuthContext';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';

const ImageOverLay = styled.div`
  z-index: 110;
  background-image: linear-gradient(to right, rgba(29, 25, 27, .86) 11%, rgba(0, 0, 0, 0));
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  height: 100%;
`;

const ImageContainer = styled.div`
  z-index: 1000;
  display: flex;
  position: relative;
  height: 88vh;
  color: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  opacity: ${({ $display }) => ($display ? '1' : '0')};

   ${ifProp('$transition', css`
      transition: opacity 1s;
  `)}


  ${props => props.theme.breakpoints.down('sm')} {
    height: 60vh;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    height: 25rem;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: 50%;
  background-size: cover;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: 50%;
  background-size: cover;
`;

const LiveVideoContainer = styled.div`
  position: ${({ $display }) => ($display ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  height: ${({ $display }) => ($display ? '100%' : '0')};
  width: 100%;
  opacity: ${({ $display }) => ($display ? '1' : '0')};
  z-index: 1000;

  ${ifProp('$transition', css`
    transition: opacity 1s;
  `)}
`;

LivePlayerBlock.propTypes = {
  data: livePlayerBlockShape,
  liveStreamPosterImage: shape({
    posterCover: livePlayerBlockShape,
  }),
};

function LivePlayerBlock({ data, liveStreamPosterImage }) {
  const { user } = useAuth();
  const { setCoverClicked, state: { coverClicked } } = useVideoPlayerContext();
  const livePlayerCover = get(data, 'livePlayerCover');
  const livePlayerBackground = get(data, 'livePlayerBackground');
  const livePlayerSidebar = get(data, 'livePlayerSidebar');
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoPlayerDisplay, setVideoPlayerDisplay] = useState(false);
  const [startPlayerCoverTransition, setStartPlayerCoverTransition] = useState(false);
  const [startPlayerTransition, setStartPlayerTransition] = useState(false);
  const [showVideoCover, setShowVideoCover] = useState(true);
  const [videoCoverDisplay, setVideoCoverDisplay] = useState(true);
  const videoExt = ((video) => { return video ? video.url.split('.').pop() : null});  // filetype restriction on CS - guaranteed to be mp4 or webm
  const { state: { pipReturnId } } = usePictureInPictureContext();

  useEffect(() => {
    if (pipReturnId && pipReturnId === 'live-player') {
      handlePipReturn();
    }
  }, [pipReturnId]);

  useEffect(() => {
    if(showVideoPlayer) {
      startVideoTransition();
    }
  },[showVideoPlayer]);

  useEffect(() => {
    if (user || coverClicked) {
      setVideoPlayerDisplay(true);
      setShowVideoPlayer(true);
      setShowVideoCover(false);
    }
  }, [user]);

  useEffect(() => { //since video cover display defaults to true, any time it's set to false is when it's clicked
    if (videoCoverDisplay === false && window) {
      setCoverClicked(true)
    }
  }, [videoCoverDisplay]);

  const startVideoTransition = () => {
    setStartPlayerCoverTransition(true)
    setTimeout(() => {
      setVideoCoverDisplay(false);
      setStartPlayerTransition(true)
      setTimeout(() => {
        setShowVideoCover(false)
        setVideoPlayerDisplay(true)
        setVideoCoverDisplay(false);
      }, 700)
    }, 400)
  };

  const handlePipReturn = () => {
    setShowVideoCover(false)
    setShowVideoPlayer(true);
    setVideoCoverDisplay(false);
    setTimeout(() => {
      setVideoPlayerDisplay(true)
    }, 400)
  };

  const dynamicLivePlayerCover = get(liveStreamPosterImage, 'posterCover');

  const posterCover = (dynamicLivePlayerCover?.video ||
    dynamicLivePlayerCover?.videoThumbnail ||
    dynamicLivePlayerCover?.image ) ? dynamicLivePlayerCover : livePlayerCover;

  const posterImage = autoOptimizeImage(posterCover?.videoThumbnail?.url);

  return (
    <>
      <Helmet>
        <link
          rel='preload'
          href={posterImage}
          as='image'
        />
      </Helmet>

      {showVideoCover && (
        <ImageContainer
        $display={videoCoverDisplay}
        $transition={startPlayerCoverTransition}
        >
        {!user && !coverClicked && (
          <>
            <ImageOverLay />
            {posterCover?.video ? (
              <Video
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={true}
                poster={posterImage}
              >
                <source src={posterCover?.video?.url} type={`video/${videoExt(posterCover?.video)}`} />
              </Video>
            ) : (
              <Image src={autoOptimizeImage(posterCover?.image?.url)} alt={posterCover?.altImageText}/>
            )}
            <LivePlayerCoverContent
              data={posterCover?.livePlayerCoverContent}
              showVideoPlayer={showVideoPlayer}
            />
          </>
        )}
        </ImageContainer>
      )}
      <LiveVideoContainer $display={videoPlayerDisplay} $transition={startPlayerTransition}>
        <LivePlayerProvider>
          <LoggedInLiveVideo
            isHomepageRedesign
            data={livePlayerSidebar}
            background={livePlayerBackground?.image?.url}
            setShowVideoPlayer={setShowVideoPlayer}
            showVideoPlayer={showVideoPlayer}
          />
        </LivePlayerProvider>
      </LiveVideoContainer>
    </>
  );
}

export default LivePlayerBlock;
